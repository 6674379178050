import { TextArea, Button, H4, Stack, P } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { SetState } from '../types/global';

const StyledTextArea = styled(TextArea)`
  div[role='tooltip'] {
    width: 550px;
    max-width: none;
  }
  div[role='tooltip'] > div {
    max-width: none;
  }
`;

const StyledP = styled(P)`
  width: 525px;
`;

interface BatchAvailsProps {
  initialBatchText: string;
  updateBatchText: SetState<string>;
  executeBatchAvails: () => void;
}

const BatchAvails: React.FC<BatchAvailsProps> = ({
  initialBatchText,
  updateBatchText,
  executeBatchAvails,
}) => {
  const [batchText, setBatchText] = useState<string>(initialBatchText);

  useEffect(() => {
    setBatchText(initialBatchText);
  }, [initialBatchText]);

  const handleBatchClick = (): void => {
    if (batchText === initialBatchText) {
      executeBatchAvails();
    } else {
      updateBatchText(batchText);
    }
  };

  return (
    <div>
      <div className="flexBreak">
        <Stack gap="0.25rem">
          <H4>Batch Targeting</H4>
          <StyledP>
            Enter the date, product and state to the table above and any
            additional batch targets in the field below.
          </StyledP>

          <div id="batch_body">
            <Stack alignItems="start" gap="16px">
              <StyledTextArea
                id="list_of_subs"
                label="Enter suburbs, regions and targets"
                placeholder="Enter suburb list here"
                spellCheck="false"
                tooltip={
                  <div>
                    <p>
                      Batch targeting also supports any regions and property
                      attributes.
                    </p>
                    <p>For example:</p>
                    <ul>
                      <li>
                        When targeting a region enter
                        'target=adregion_act_weston_creek___greater_region'
                      </li>
                      <li>
                        When targeting a price enter 'target=Price: $750k-$1m'
                      </li>
                    </ul>
                    <p>
                      Note: To run a batch for regions and property attributes
                      you need to include 'target=' at the start
                    </p>
                  </div>
                }
                tooltipProps={{
                  placement: 'right-start',
                  dangerouslySetClassNames: {
                    contentWrapper: 'tooltip-content',
                  },
                }}
                onChange={(e) => {
                  setBatchText(e.target.value);
                }}
                value={batchText}
              />

              <Button sizeVariant="small" onClick={handleBatchClick}>
                Run Batch Avails
              </Button>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};
export default BatchAvails;
