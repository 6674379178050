import {
  Autocomplete,
  ListboxOption,
  StarMd,
} from '@rea-group/construct-kit-core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Target, TargetMapOptionsToReactParams } from '../types/global';
import { fetchTargetSuggestions } from '../utils';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 200%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;
interface GetTargetDetailsProps {
  getTargetDetails: (val: Target) => void;
}

const GetTargetDetails: React.FC<GetTargetDetailsProps> = ({
  getTargetDetails,
}) => {
  const [targetOptions, setTargetOptions] = useState<Target[]>([]);

  const onInputChange = (value: string): void => {
    if (value && value.length > 2) {
      fetchTargetSuggestions(value).then((fetchedSuggestions) => {
        setTargetOptions(fetchedSuggestions);
      });
    }
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }: TargetMapOptionsToReactParams) => {
    const iconClass =
      option.type === 'sub'
        ? 'suburb'
        : option.type === 'target'
          ? 'target'
          : option.type === 'proptype'
            ? 'proptype'
            : '';

    return (
      <ListboxOption isActive={isActive} sizeVariant={sizeVariant}>
        {iconClass ? (
          <span className={`target-icon ${iconClass}`}></span>
        ) : (
          <StarMd />
        )}
        {mapOptionToString(option)}
      </ListboxOption>
    );
  };

  const mapOptionToStr = (option: Target) => {
    if (!option) return '';
    return option.value.toString();
  };

  return (
    <div className="topInputContainer">
      <StyledAutocomplete
        id="targeting"
        name="target"
        label="Get target details:"
        placeholder="Target Name"
        onInputChange={onInputChange}
        filterOptions={(options) => options}
        listboxMaxHeight="40rem"
        mapOptionToListNode={mapOptionToReact}
        onSelectedOptionChange={getTargetDetails} // when option selected
        mapOptionToString={mapOptionToStr}
        options={targetOptions}
        sizeVariant="small"
        tooltip={
          <p>
            <span className="target-icon proptype" />- Proptype
            <br />
            <span className="target-icon suburb" />- Suburb
            <br />
            <span className="target-icon target" />- Targeting
          </p>
        }
      />
    </div>
  );
};
export default GetTargetDetails;
