import React from 'react';
import { TextInput } from '@rea-group/construct-kit-core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SizeVariant } from '../types/global';

interface DateInputProps {
  id: string;
  name: string;
  className: string;
  label: string;
  value: string;
  onChange: (date: string) => void;
  disabled: boolean | undefined;
  sizeVariant: SizeVariant;
}
const DateInput: React.FC<DateInputProps> = ({
  id,
  name,
  className,
  label,
  value,
  onChange,
  disabled,
  sizeVariant,
}) => {
  const parseDate = (dateString: string): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const handleDateChange = (date: Date | null) => {
    onChange(date ? date.toISOString().slice(0, 10) : '');
  };

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={parseDate(value)}
      onChange={handleDateChange}
      disabled={disabled}
      customInput={
        <TextInput
          id={id}
          type="text"
          name={name}
          label={label}
          hideLabel={true}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className={className}
          sizeVariant={sizeVariant}
          value={value}
        />
      }
      calendarClassName="calendar-class"
    />
  );
};

export default DateInput;
