import { Select } from '@rea-group/construct-kit-core';
import React from 'react';
import { LobType, SetState } from '../types/global';
import { lobs } from '../data/lobData';

interface LobSelectionProps {
  lob: LobType;
  setLob: SetState<LobType>;
}

const LobSelection: React.FC<LobSelectionProps> = ({ lob, setLob }) => {
  const handleOptionChange = (selectedOption?: LobType | null): void => {
    if (selectedOption) {
      setLob(selectedOption);
      localStorage.setItem('QA_LOB', JSON.stringify(selectedOption));
    }
  };

  return (
    <div className="topInputContainer">
      <Select
        onSelectedOptionChange={handleOptionChange}
        options={lobs}
        defaultSelectedOption={lob}
        label="Select Line of Business"
        sizeVariant="small"
      />
    </div>
  );
};
export default LobSelection;
