import {
  Autocomplete,
  ListboxOption,
  StarMd,
} from '@rea-group/construct-kit-core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  SizeVariant,
  Target,
  TargetMapOptionsToReactParams,
  TableRowKey,
} from '../types/global';
import { fetchTargetSuggestions } from '../utils';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 200%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;

interface TargetingBoxProps {
  id: string;
  idx: number;
  name: string;
  value: string;
  handleChange: (name: TableRowKey, idx: number) => (option: Target) => void;
  disabled: boolean | undefined;
  className?: string;
  sizeVariant: SizeVariant;
}
const TargetingBox: React.FC<TargetingBoxProps> = ({
  id,
  idx,
  name,
  value,
  handleChange,
  sizeVariant,
  disabled,
}) => {
  const [targetOptions, setTargetOptions] = useState<Target[]>([]);
  const [targetInput, setTargetInput] = useState<string>(value);

  useEffect(() => {
    setTargetInput(value);
  }, [value]);

  const onInputChange = (value: string): void => {
    if (value && value.length > 2) {
      fetchTargetSuggestions(value).then((fetchedSuggestions: Target[]) => {
        setTargetOptions(fetchedSuggestions);
      });
    }
    setTargetInput(value);
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }: TargetMapOptionsToReactParams) => {
    const iconClass =
      option.type === 'sub'
        ? 'suburb'
        : option.type === 'target'
          ? 'target'
          : option.type === 'proptype'
            ? 'proptype'
            : '';

    return (
      <ListboxOption
        title={mapOptionToString(option)}
        isActive={isActive}
        sizeVariant={sizeVariant}
      >
        <span title={mapOptionToString(option)}>
          {iconClass ? (
            <span className={`target-icon ${iconClass}`}></span>
          ) : (
            <StarMd />
          )}
          {mapOptionToString(option)}
        </span>
      </ListboxOption>
    );
  };
  const mapOptionToStr = (option: Target): string => {
    if (!option) return '';
    return option.value.toString();
  };

  return (
    <StyledAutocomplete
      id={id}
      name={name}
      hideLabel={true}
      label={name}
      placeholder={'Target Name'}
      onInputChange={onInputChange}
      filterOptions={(e) => {
        return e;
      }}
      listboxMaxHeight="40rem"
      mapOptionToListNode={mapOptionToReact}
      onSelectedOptionChange={handleChange(name as TableRowKey, idx)}
      mapOptionToString={mapOptionToStr}
      options={targetOptions}
      inputValue={targetInput}
      sizeVariant={sizeVariant}
      disabled={disabled}
    />
  );
};
export default TargetingBox;
