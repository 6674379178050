import React from 'react';
import {
  Button,
  BinLg,
  Small,
  Select,
  Strong,
} from '@rea-group/construct-kit-core';
import TargetingBox from './TargetingBox';
import ProductBox from './ProductBox';
import DateInput from './DateInput';
import { stateOptions } from '../data/stateOptionsData';
import { LobType, SetState, TableRowKey, Target } from '../types/global';
import {
  StateOption,
  TableHeaderItem,
  TableRow,
  TableRowState,
} from '../types/Table.types';

interface TableProps {
  lob: LobType;
  setTableRows: SetState<TableRowState>;
  getTargetDetails: (val: Target | string) => void;
  tableRows: TableRowState;
  getQuickAvails: (request_id: string) => void;
  tableHeader: TableHeaderItem[];
}

const Table: React.FC<TableProps> = ({
  lob,
  setTableRows,
  getTargetDetails,
  tableRows,
  getQuickAvails,
  tableHeader,
}) => {
  const updateTableRow = (
    index: number,
    key: TableRowKey,
    value: string,
  ): void => {
    const updatedRows: TableRow[] = [...tableRows.rows];
    updatedRows[index][key] = value;
    setTableRows({ rows: updatedRows });
  };

  const handleDateChange =
    (name: TableRowKey, index: number) =>
    (date: string): void => {
      updateTableRow(index, name, date);
    };

  const handleTargetChange =
    (name: TableRowKey, index: number) =>
    (option: Target): void => {
      const { value, type } = option;
      const targeting = type + '=' + value;
      updateTableRow(index, name, targeting);
    };

  const handleProductChange = (productName: string, index: number): void => {
    updateTableRow(index, 'product', productName);
  };

  const handleStateChange = (index: number, stateValue: string): void => {
    updateTableRow(index, 'state', stateValue);
  };

  const handleRemoveSpecificRow = (idx: number) => {
    const updatedRows = tableRows.rows.filter((_, index) => index !== idx);
    setTableRows({ rows: updatedRows });
  };

  const formatDate = (str_date: string): string => {
    const date = new Date(str_date);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 101).toString().substring(1);
    const day = (date.getDate() + 100).toString().substring(1);
    return day + '/' + month + '/' + year;
  };

  const formatNumber = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getDefaultStateOption = (option: string): StateOption => {
    return (
      stateOptions.find((state) => state.value === option) || {
        label: '',
        value: '',
      }
    );
  };

  return (
    <div>
      <table className="table table-bordered table-hover" id="tab_logic">
        <thead>
          <tr>
            <th className="table-header" style={{ width: '120px' }}>
              <Small>
                <Strong>Start Date</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '120px' }}>
              <Small>
                <Strong>End Date</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '300px' }}>
              <Small>
                <Strong>Product</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '100px' }}>
              <Small>
                <Strong>State</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '145px' }}>
              <Small>
                <Strong>Targeting 1</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '145px' }}>
              <Small>
                <Strong>Targeting 2</Strong>
              </Small>
            </th>
            <th
              className="table-header"
              style={{ width: '145px', display: tableHeader[0].show }}
            >
              <Small>
                <Strong>Targeting 3</Strong>
              </Small>
            </th>
            <th
              className="table-header"
              style={{ width: '145px', display: tableHeader[1].show }}
            >
              <Small>
                <Strong>Targeting 4</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '110px' }}>
              <Small>
                <Strong>Total</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '110px' }}>
              <Small>
                <Strong>Available</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '130px' }}>
              <Small>
                <Strong>Contending</Strong>
              </Small>
            </th>
            <th className="table-header" style={{ width: '75px' }}>
              <Small>
                <Strong>Action</Strong>
              </Small>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRows.rows.map((item: TableRow, idx) => (
            <tr id={'row_' + idx} key={idx} className="tableRow">
              <td className="resultTable">
                {item.processed ? (
                  <div className="buttonCell">
                    {formatDate(item.start_date)}
                  </div>
                ) : (
                  <DateInput
                    name="start_date"
                    label="start_date"
                    id={'start_date_' + idx}
                    value={item.start_date ?? ''}
                    onChange={handleDateChange('start_date', idx)}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td className="resultTable">
                {item.processed ? (
                  <div className="buttonCell">{formatDate(item.end_date)}</div>
                ) : (
                  <DateInput
                    name="end_date"
                    label="end_date"
                    id={'end_date' + idx}
                    value={item.end_date ?? ''}
                    onChange={handleDateChange('end_date', idx)}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td className="resultTable">
                {item.processed ? (
                  <div className="buttonCell">{item.product}</div>
                ) : (
                  <ProductBox
                    name="product"
                    id={'product_' + idx}
                    index={idx}
                    lob={lob}
                    value={item.product}
                    handleProductChange={handleProductChange}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>

              <td className="resultTable">
                {item.processed ? (
                  <div className="buttonCell">{item.state}</div>
                ) : (
                  <Select
                    id={'state_' + idx}
                    label="state"
                    sizeVariant="small"
                    onSelectedOptionChange={(selectedOption) => {
                      if (selectedOption) {
                        handleStateChange(idx, selectedOption.value);
                      }
                    }}
                    hideLabel={true}
                    placeholder="Select"
                    options={stateOptions}
                    defaultSelectedOption={getDefaultStateOption(item.state)}
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td className="resultTable">
                {item.processed ? (
                  item.targeting_1 === '' ? (
                    <div></div>
                  ) : (
                    <div
                      className="targetOpen buttonCell"
                      onClick={(e) => {
                        getTargetDetails(item.targeting_1);
                      }}
                    >
                      {item.targeting_1}
                    </div>
                  )
                ) : (
                  <TargetingBox
                    name="targeting_1"
                    id={'targeting_1_' + idx}
                    idx={idx}
                    value={item.targeting_1}
                    handleChange={handleTargetChange}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td className="resultTable">
                {item.processed ? (
                  item.targeting_2 === '' ? (
                    <div></div>
                  ) : (
                    <div
                      className="targetOpen buttonCell"
                      onClick={(e) => {
                        getTargetDetails(item.targeting_2);
                      }}
                    >
                      {item.targeting_2}
                    </div>
                  )
                ) : (
                  <TargetingBox
                    name="targeting_2"
                    id={'targeting_2_' + idx}
                    idx={idx}
                    value={item.targeting_2}
                    handleChange={handleTargetChange}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td
                className="resultTable"
                style={{ display: tableHeader[0].show }}
              >
                {item.processed ? (
                  item.targeting_3 === '' ? (
                    <div></div>
                  ) : (
                    <div
                      className="targetOpen buttonCell"
                      onClick={(e) => {
                        getTargetDetails(item.targeting_3);
                      }}
                    >
                      {item.targeting_3}
                    </div>
                  )
                ) : (
                  <TargetingBox
                    name="targeting_3"
                    id={'targeting_3_' + idx}
                    idx={idx}
                    value={item.targeting_3}
                    handleChange={handleTargetChange}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td
                className="resultTable"
                style={{ display: tableHeader[1].show }}
              >
                {item.processed ? (
                  item.targeting_4 === '' ? (
                    <div></div>
                  ) : (
                    <div
                      className="targetOpen buttonCell"
                      onClick={(e) => {
                        getTargetDetails(item.targeting_4);
                      }}
                    >
                      {item.targeting_4}
                    </div>
                  )
                ) : (
                  <TargetingBox
                    name="targeting_4"
                    id={'targeting_4_' + idx}
                    idx={idx}
                    value={item.targeting_4}
                    handleChange={handleTargetChange}
                    className="form-control"
                    sizeVariant="small"
                    disabled={item.isDisabled}
                  />
                )}
              </td>
              <td id={'results_total_' + idx} className="resultTable">
                <div className="buttonCell">{formatNumber(item.total)}</div>
              </td>
              <td id={'results_available_' + idx} className="resultTable">
                <div className="buttonCell">{formatNumber(item.available)}</div>
              </td>
              <td className="resultTable" id={'contending_' + idx}>
                {item.contending === '' ? (
                  ''
                ) : (
                  <div className="buttonCell">
                    <a href={item.contending} rel="noreferrer" target="_blank">
                      View
                    </a>
                  </div>
                )}
              </td>
              <td className="resultTable">
                <Button
                  id={'run_btn_' + idx}
                  className="btn btn-outline-danger btn-sm"
                  sizeVariant="small"
                  onClick={() => {
                    getQuickAvails(item.id);
                  }}
                  style={{ display: item.runBtnDisplay }}
                >
                  Run
                </Button>
                <div style={{ display: item.deleteBtnDisplay }}>
                  <BinLg
                    onClick={() => handleRemoveSpecificRow(idx)}
                    className="customCursor"
                    style={{ display: item.deleteBtnDisplay }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
