import React, { useState, useEffect } from 'react';
import {
  Modal,
  Dialog,
  Card,
  spacingHelper,
  Button,
  Inline,
  Small,
  H2,
  Strong,
} from '@rea-group/construct-kit-core';
import { v4 as uuidv4 } from 'uuid';
import Footer from './Footer';
import Header from './Header';
import LobSelection from './LobSelect';
import GetTargetDetails from './GetTargetDetails';
import BatchAvails from './BatchAvails';
import AdditionalTargeting from './AdditionalTargeting';
import Table from './Table';
import styled from 'styled-components';
import { TableHeaderItem, TableRow, TableRowState } from '../types/Table.types';
import { LobType, Target } from '../types/global';
import {
  AvailabilityReport,
  BackgroundCheckResponse,
  SubDetail,
  TargetDetail,
  TargetList,
} from '../types/Body.types';

const ContentContainer = styled(Card)`
  padding: ${spacingHelper('medium')};
  width: 800px;
`;

const StyledDialogBox = styled(Dialog)`
  max-width: none;
`;

const getFutureDate = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

const Body: React.FC = () => {
  const startDate = getFutureDate(1).toISOString().substring(0, 10);
  const endDate = getFutureDate(28).toISOString().substring(0, 10);

  let backgroundRequests: { [key: string]: number } = {};

  //modal toggle
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  //initial state of table
  const [tableRows, setTableRows] = useState<TableRowState>({
    rows: [
      {
        id: uuidv4(),
        start_date: startDate,
        end_date: endDate,
        state: '',
        product:
          'REA Buy - Search Results - Responsive Native Single Image - Suburb',
        targeting_1: '',
        targeting_2: '',
        targeting_3: '',
        targeting_4: '',
        total: '',
        available: '',
        contending: '',
        deleteBtnDisplay: 'none',
        runBtnDisplay: 'inline-block',
        processed: false,
      },
    ],
  });

  const toggleValue = localStorage.getItem('targ_toggle_val') || 'none';
  const [tableHeader, setTableHeader] = useState<TableHeaderItem[]>([
    {
      targeting_3: 'TARGETING 3',
      show: toggleValue,
    },
    {
      targeting_4: 'TARGETING 4',
      show: toggleValue,
    },
  ]);

  const [buttonText, setButtonText] = useState<string>(
    toggleValue === 'table-cell' ? 'Less' : 'More', //initial button value
  );

  const [batchText, setBatchText] = useState<string>('');

  //lob state
  const lobData =
    localStorage.getItem('QA_LOB') || '{ "label": "N/A", "value": "n/a" }';
  const [lob, setLob] = useState<LobType>(JSON.parse(lobData));

  const [targetList, setTargetList] = useState<TargetList>({
    type: '',
    results: [],
  });

  const getTargetDetails = async (queryParam: Target | string) => {
    let searchParam;

    //launch modal
    if (typeof queryParam === 'string') {
      searchParam = queryParam.includes('=') ? queryParam : `sub=${queryParam}`;
    } else if (queryParam.type) {
      if (queryParam.type === 'proptype') {
        return;
      }
      searchParam = queryParam.type + '=' + queryParam.value;
    }

    let urlparms = new URLSearchParams(searchParam).toString();

    const response = await fetch(
      `${process.env.REACT_APP_QUICK_AVAILS_API}/target_details?` + urlparms,
    );
    const data: TargetList = await response.json();
    setTargetList(data);
    setIsModalOpen(true);
  };

  const copyToBatchAvails = () => {
    let resultString = targetList.results
      .map((item) => {
        if (targetList.type === 'target') {
          const targetDetail = item as TargetDetail;
          return targetDetail.subname;
        } else {
          const subDetail = item as SubDetail;
          return subDetail.region
            .split(',')
            .map((region: string) => `target=${region}`)
            .join(',');
        }
      })
      .join(',');

    //$('#list_of_subs').val(resultString.replace(/,/g, '\n'));
    //$('[id^=state_]').val(state_resultString);

    setBatchText(resultString.replace(/,/g, '\n'));
    setIsModalOpen(false);
  };

  const showHideColumns = (): void => {
    const currentVisibility = localStorage.getItem('targ_toggle_val') || 'none';
    const newVisibility =
      currentVisibility === 'table-cell' ? 'none' : 'table-cell';
    const display = newVisibility === 'table-cell' ? '' : 'none';

    setTableHeader([
      { targeting_3: 'TARGETING 3', show: (tableHeader[0].show = display) },
      { targeting_4: 'TARGETING 4', show: (tableHeader[1].show = display) },
    ]);

    //set localStorage value.
    localStorage?.setItem('targ_toggle_val', newVisibility);
  };

  //we need the last line of the table.
  const batchAvails = (): void => {
    if (batchText === '') return;

    //get last row
    //clear last row
    let rows: TableRow[] = tableRows.rows;
    let lastIndex = rows.length - 1;
    let lastRow = rows[lastIndex];

    //get product
    if (lastRow.product === '') {
      alert('Please specify a product!');
      return;
    }

    let batchSuburbs = batchText.split('\n');

    if (batchSuburbs[0].indexOf('target=') >= 0) {
      //clear state
      rows[lastIndex].state = '';
    }

    //remove first rows
    rows.splice(lastIndex, 1);
    //update table state
    setTableRows({
      rows: rows,
    });

    batchSuburbs.slice(0, 60).forEach((batchLine) => {
      if (batchLine !== '') {
        const targeting_1 =
          batchLine.indexOf('=') === -1 ? `sub=${batchLine}` : batchLine;
        const newRow = {
          ...lastRow,
          id: uuidv4(),
          targeting_1,
          targeting_2: '',
          targeting_3: '',
          targeting_4: '',
          total: 'Processing',
          available: '',
          contending: '',
          deleteBtnDisplay: 'none',
          runBtnDisplay: 'none',
          isDisabled: true,
          processed: false,
        };
        rows.push(newRow);
        getQuickAvails(newRow.id);
      }
    });
    //update table state
    setTableRows({ rows });
    //Add new row
    handleAddRow();

    if (batchSuburbs.length > 60) {
      alert(
        'Batch Avails can only run 60 requests at a time.\nNot all of your requests have been processed',
      );
    }
  };

  const getQuickAvails = (requestId: string) => {
    const {
      start_date,
      end_date,
      state,
      product,
      targeting_1,
      targeting_2,
      targeting_3,
      targeting_4,
    } = tableRows.rows.find((row) => row.id === requestId) || {};

    updateRowState(requestId, {
      total: 'Processing',
      isDisabled: true,
      runBtnDisplay: 'none',
    });

    const postData = {
      start_date: start_date || '',
      end_date: end_date || '',
      product: product || '',
      state: state || '',
      targeting_1: targeting_1 || '',
      targeting_2: targeting_2 || '',
      targeting_3: targeting_3 || '',
      targeting_4: targeting_4 || '',
    };

    makeInitialRequest(postData, requestId);
    const lastRow = tableRows.rows[tableRows.rows.length - 1];
    if (lastRow.isDisabled) {
      handleAddRow();
    }
  };

  const makeInitialRequest = async (
    postData: Record<string, string>,
    requestId: string,
  ): Promise<void> => {
    const urlparms = new URLSearchParams(
      postData as Record<string, string>,
    ).toString();
    const url = `${process.env.REACT_APP_QUICK_AVAILS_API}/run_avails`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cache: 'no-cache',
        body: urlparms,
      });

      const data: AvailabilityReport = await response.json();
      if (data) {
        if (data.Contending) {
          updateRowState(requestId, { contending: data.Contending });
        }

        if (data.error) {
          if (data.error === 'Unknown response') {
            //Likely a request limit being hit. Let's retry this in 20ish seconds
            updateRowState(requestId, {
              total: 'Requests throttled. Retrying shortly.',
            });

            setTimeout(function () {
              makeInitialRequest(postData, requestId);
            }, 10000);
          } else {
            updateRowState(requestId, {
              total: data.error || '',
              isDisabled: false,
              runBtnDisplay: 'inline-block',
            });
            if (data.error === "You don't have permission to view this page") {
              //offerLogin();
            }
          }
        } else if (data.job_id) {
          updateRowState(requestId, { total: 'Running' });
          //job retry
          backgroundRequests[data.job_id] = 0;
          setTimeout(function () {
            handleBackgroundCheck(requestId, data.job_id!);
          }, 10000);
        } else {
          //update tablerow state.
          updateRowState(requestId, {
            total: data.Total || '',
            available: data.Available || '',
            processed: true,
            deleteBtnDisplay: 'inline-block',
            runBtnDisplay: 'none',
          });
        }
      }
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
    }
  };

  const handleBackgroundCheck = async (requestId: string, jobId: string) => {
    const postData = { job_id: jobId };
    let waitTime = 3000;
    backgroundRequests[jobId]++;

    if (backgroundRequests[jobId] > 7) {
      waitTime = 8000;
      updateRowState(requestId, { total: 'Taking longer than expected' });
    }
    if (backgroundRequests[jobId] > 14) {
      waitTime = 12000;
      updateRowState(requestId, { total: 'Taking much longer than expected' });
    }
    if (backgroundRequests[jobId] > 30) {
      updateRowState(requestId, { total: 'Cancelled', isDisabled: false });
      return;
    }

    const urlparms = new URLSearchParams(postData).toString();
    const url = `${process.env.REACT_APP_QUICK_AVAILS_API}/background`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cache: 'no-cache',
        body: urlparms,
      });

      const data: BackgroundCheckResponse = await response.json();
      if (data) {
        if (data.error) {
          updateRowState(requestId, {
            total: data.error,
            isDisabled: false,
            runBtnDisplay: 'inline-block',
          });
          if (data.error === "You don't have permission to view this page") {
            //offerLogin();
            alert('Login Required');
          }
        } else if (data.job_id) {
          setTimeout(function () {
            handleBackgroundCheck(requestId, jobId);
          }, waitTime);
        } else {
          if (data.Status !== 'completed') {
            setTimeout(function () {
              handleBackgroundCheck(requestId, jobId);
            }, waitTime);
          } else {
            //update tablerow state.
            updateRowState(requestId, {
              total: data.Total || '',
              available: data.Available || '',
              processed: true,
              deleteBtnDisplay: 'inline-block',
              runBtnDisplay: 'none',
            });
          }
        }
      }
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
    }
  };

  const handleAddRow = (): void => {
    const item = {
      id: uuidv4(),
      start_date:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].start_date
          : '',
      end_date:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].end_date
          : '',
      product:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].product
          : '',
      state:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].state
          : '',
      targeting_1: '',
      targeting_2: '',
      targeting_3: '',
      targeting_4: '',
      total: '',
      available: '',
      contending: '',
      deleteBtnDisplay: 'none',
      runBtnDisplay: 'inline-block',
      processed: false,
    };
    setTableRows({
      rows: [...tableRows.rows, item],
    });
  };

  const updateRowState = (requestId: string, updates: Partial<TableRow>) => {
    setTableRows((currentState) => {
      const newState = Object.assign({}, currentState);
      const newStateRow = newState.rows.find((x) => x.id === requestId);
      if (!newStateRow) return currentState;
      Object.assign(newStateRow, updates);
      return newState;
    });
  };

  useEffect(() => {
    batchAvails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchText]);

  return (
    <div className="container">
      <div className="pageTop">
        <Header />
        <div className="topContainer">
          <Inline alignItems="flex-start" gap="medium">
            <LobSelection lob={lob} setLob={setLob} />
            <GetTargetDetails getTargetDetails={getTargetDetails} />
          </Inline>
        </div>
        <div className="flexBreak">
          <Table
            lob={lob}
            getTargetDetails={getTargetDetails}
            setTableRows={setTableRows}
            tableRows={tableRows}
            getQuickAvails={getQuickAvails}
            tableHeader={tableHeader}
          />
        </div>
        <AdditionalTargeting
          showHideColumns={showHideColumns}
          btnText={buttonText}
          changeButtonText={setButtonText}
        />
      </div>
      <div className="pageBottom">
        <div className="flexBreak">
          <BatchAvails
            initialBatchText={batchText}
            updateBatchText={setBatchText}
            executeBatchAvails={batchAvails}
          />
        </div>
        <Footer />
      </div>

      <Modal
        opened={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        getAppElement={() => '.container'}
      >
        <StyledDialogBox
          header={<H2>Suburb/Region/Target</H2>}
          footer={
            <Button
              id="run_btn_batch"
              className="btn btn-outline-danger btn-sm"
              sizeVariant="small"
              onClick={() => copyToBatchAvails()}
            >
              Run Batch Avails
            </Button>
          }
          autoFullScreen={true}
          headerAlignment="left"
          onClose={() => setIsModalOpen(false)}
        >
          <ContentContainer>
            <table className="batchTable">
              <thead>
                {targetList.type === 'sub' ? (
                  <tr>
                    <th className="table-header" style={{ width: '200px' }}>
                      <Small>
                        <Strong>Suburb</Strong>
                      </Small>
                    </th>
                    <th className="table-header" style={{ width: '60px' }}>
                      <Small>
                        <Strong>State</Strong>
                      </Small>
                    </th>
                    <th className="table-header">
                      <Small>
                        <Strong>Region(s)</Strong>
                      </Small>
                    </th>
                  </tr>
                ) : (
                  <tr>
                    <th className="table-header" style={{ width: '200px' }}>
                      <Small>
                        <Strong>Region</Strong>
                      </Small>
                    </th>
                    <th className="table-header" style={{ width: '60px' }}>
                      <Small>
                        <Strong>State</Strong>
                      </Small>
                    </th>
                    <th className="table-header">
                      <Small>
                        <Strong>Suburb(s)</Strong>
                      </Small>
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {targetList.results.map((item, idx) => {
                  if (targetList.type === 'sub') {
                    const sub_detail_item = item as SubDetail;
                    return (
                      <tr key={'map_' + idx}>
                        <td>{sub_detail_item.suburb}</td>
                        <td>{sub_detail_item.state.toUpperCase()}</td>
                        <td>
                          {sub_detail_item.region !== null &&
                            sub_detail_item.region
                              .split(',')
                              .map((val: string, idx: number) => {
                                return (
                                  <div
                                    title={val}
                                    key={sub_detail_item.suburb + '_' + idx}
                                    className="textwrapstyle pill-link"
                                    onClick={(e) => {
                                      getTargetDetails('target=' + val);
                                    }}
                                  >
                                    {val}
                                  </div>
                                );
                              })}
                        </td>
                      </tr>
                    );
                  } else {
                    const target_detail_item = item as TargetDetail;
                    return (
                      <tr key={'map_' + idx}>
                        <td>{target_detail_item.region}</td>
                        <td>{target_detail_item.state.toUpperCase()}</td>
                        <td>
                          {target_detail_item.region !== null &&
                            target_detail_item.neatname
                              .split(',')
                              .map((val, idx) => {
                                return (
                                  <div
                                    title={val}
                                    key={target_detail_item.region + '_' + idx}
                                    className="textwrapstyle pill-link"
                                    onClick={(e) => {
                                      getTargetDetails('sub=' + val);
                                    }}
                                  >
                                    {val}
                                  </div>
                                );
                              })}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </ContentContainer>
        </StyledDialogBox>
      </Modal>
    </div>
  );
};

export default Body;
