import { LobType } from '../types/global';

export const lobs: LobType[] = [
  {
    value: 'Developer Sales - NSW',
    label: 'Developer',
  },
  {
    value: 'Developer Sales - WA',
    label: 'Developer WA',
  },
  {
    value: 'Media Sales - NSW',
    label: 'Media',
  },
  {
    value: 'n/a',
    label: 'N/A',
  },
];
