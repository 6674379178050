import React from 'react';
import { H1 } from '@rea-group/construct-kit-core';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div>
        <H1>Quick Avails</H1>
        <p>Find product availabilty for your customers</p>
      </div>
    </header>
  );
};

export default Header;
