import { Autocomplete, ListboxOption } from '@rea-group/construct-kit-core';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  LobType,
  ProductMapOptionToReactParams,
  SizeVariant,
} from '../types/global';
import { ProductOptions } from '../types/ProductBox.types';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 300%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;

interface ProductBoxProps {
  id: string;
  index: number;
  name: string;
  value: string;
  lob: LobType;
  handleProductChange: (name: string, idx: number) => void;
  disabled: boolean | undefined;
  className?: string;
  sizeVariant: SizeVariant;
}
const ProductBox: React.FC<ProductBoxProps> = ({
  id,
  index,
  name,
  value,
  lob,
  handleProductChange,
  sizeVariant,
  disabled,
}) => {
  const [productOptions, setProductOptions] = useState<ProductOptions[]>([]);

  const PRODUCT_SUGGEST_API = `${process.env.REACT_APP_QUICK_AVAILS_API}/auto_complete_product`;

  const fetchProductSuggestions = async (
    productQuery: string,
  ): Promise<ProductOptions[]> => {
    let urlParams = new URLSearchParams({
      query: productQuery,
      team: lob.value,
    }).toString();
    const url = `${PRODUCT_SUGGEST_API}?${urlParams}`;

    const response = await fetch(url);
    const data: ProductOptions[] = await response.json();
    return data;
  };

  const handleInputChange = (input: string) => {
    if (input && input.length > 2) {
      fetchProductSuggestions(input).then(
        (fetchedSuggestions: ProductOptions[]) => {
          setProductOptions(fetchedSuggestions);
        },
      );
    }
  };

  const formatProductOption = (option: ProductOptions) => {
    if (!option) return '';
    const productName = option.name.replaceAll("'", "\\'");
    const listRate = parseFloat(option.list_rate).toFixed(2);
    return `${productName} - $${listRate}`;
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }: ProductMapOptionToReactParams) => {
    return (
      <ListboxOption isActive={isActive} sizeVariant={sizeVariant}>
        <span title={formatProductOption(option)}>
          {formatProductOption(option)}
        </span>
      </ListboxOption>
    );
  };
  const mapOptionToString = (option: ProductOptions) => {
    if (!option) return '';
    return option.name.toString();
  };

  return (
    <StyledAutocomplete
      id={id}
      name={name}
      hideLabel={true}
      label={name}
      placeholder={'Product Name'}
      onInputChange={(value) => {
        handleInputChange(value);
        handleProductChange(value, index);
      }}
      filterOptions={(e) => {
        return e;
      }}
      listboxMaxHeight="40rem"
      mapOptionToListNode={mapOptionToReact}
      onSelectedOptionChange={(option) => {
        handleProductChange(option.name, index);
      }}
      mapOptionToString={mapOptionToString}
      options={productOptions}
      sizeVariant={sizeVariant}
      inputValue={value}
      disabled={disabled}
    />
  );
};
export default ProductBox;
