import React from 'react';
import logo from '../TechOpsLogo.png';

const Footer: React.FC = () => {
  return (
    <div id="techOps-logo">
      <img src={logo} alt="Logo" width="130" />
    </div>
  );
};
export default Footer;
