import { Target } from './types/global';

export const fetchTargetSuggestions = async (
  targetQuery: string,
): Promise<Target[]> => {
  const TARGET_SUGGEST_API = `${process.env.REACT_APP_QUICK_AVAILS_API}/auto_complete_targeting`;
  const postData = { query: targetQuery };
  let urlParams = new URLSearchParams(postData).toString();
  const url = `${TARGET_SUGGEST_API}?${urlParams}`;

  const response = await fetch(url);
  return response.json();
};
