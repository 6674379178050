import React from 'react';
import { Button, AddMd, RemoveMd } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { SetState } from '../types/global';

const StyledButton = styled(Button)`
  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

interface AdditionalTargetingProps {
  showHideColumns: () => void;
  btnText: string;
  changeButtonText: SetState<string>;
}
const AdditionalTargeting: React.FC<AdditionalTargetingProps> = ({
  showHideColumns,
  btnText,
  changeButtonText,
}) => {
  const handleButtonClick = (): void => {
    showHideColumns();
    changeButtonText(btnText === 'More' ? 'Less' : 'More');
  };

  return (
    <div className="TargetBtnContainer">
      <StyledButton
        id="addmore"
        icon={btnText === 'More' ? <AddMd /> : <RemoveMd />}
        iconPlacement="left"
        name="addmore"
        variant="link-primary"
        sizeVariant="small"
        onClick={handleButtonClick}
      >
        {btnText + ' targeting options'}
      </StyledButton>
    </div>
  );
};

export default AdditionalTargeting;
